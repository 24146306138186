import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast:any;
import Swal from 'sweetalert2'

@Component({
  selector: 'app-custom-wallboard-three',
  templateUrl: './custom-wallboard-three.component.html',
  styleUrls: ['./custom-wallboard-three.component.css']
})
export class CustomWallboardThreeComponent implements OnInit {
  websocket;
    socketData;
    user_type;
    loginUser;
    queueData;
    agentsData;
    agentsQueData;
    avlagent = 0;
    quesData;
    callques;
    agentloggedin =0;
    waiting =0;
    abandoned =0;
    total =0;
    idleagents;
    activeagents;
    wrapupagents;
    notreadyagents;
    admin_id;
    has_hard_id;
    hardware_id;
    show_act_wall = false;
    has_admin =false;
    has_user =false;
    doc_link;
    isVelocitor=false;
    Qcalldatas;
    NoQueueCalls;
  constructor(private serverService: ServerService, private router:Router) {  }
  ngOnInit() {
    this.user_type = localStorage.getItem('user_type');
    this.loginUser = localStorage.getItem('userId');
    this.admin_id = localStorage.getItem('admin_id');


    this.user_type = localStorage.getItem('user_type');
    if(this.user_type == 'Admin'){
      this.has_admin = true;
    } else {
      this.has_user = true;
    }
    if(this.admin_id==1053)
    this.isVelocitor=true;
    else
    this.isVelocitor=false;

  
    this.has_hard_id = localStorage.getItem('hardware_id');
    if(this.has_hard_id == ""){
        $("#addLicence").modal({"backdrop": "static"});
        this.show_act_wall = true;
    } else {
      this.checkLicenseKey();
    }
  
    }

  initSocket(){
    this.user_type = localStorage.getItem('user_type');
    this.loginUser = localStorage.getItem('userId');
    this.admin_id = localStorage.getItem('admin_id');
    // if(this.loginUser == '64'){
    //   this.websocket = new WebSocket("wss://socket.mconnectapps.com:5013/"); 
    // } else if(this.loginUser == '164'){
    //   this.websocket = new WebSocket("wss://socket.mconnectapps.com:5014/"); 
    // } else if(this.loginUser == '201'){
    //   this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4003"); 
    //   // this.websocket = new WebSocket("wss://socket.mconnectapps.com:5014/"); 
    // } else {
    //   this.websocket = new WebSocket("wss://socket.mconnectapps.com:5012/"); 
    // }


    if(this.loginUser == '64'){
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4002"); 
    } else if(this.loginUser == '201'){
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4003"); 
    } else {
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4001"); 
    }

    this.websocket.onopen = function(event) { 
      $('#sendonload').click();
      console.log('custom wallboard 3 connected');
    }

    this.websocket.onmessage = function(event) {

      var result_message: any = JSON.parse(event.data);
   // console.log(result_message);

      this.has_hard_id = localStorage.getItem('hardware_id');
      if(result_message.cust_id == this.has_hard_id || result_message[0].cust_id == this.has_hard_id){
        console.log('matched');
      } else {
        console.log('not matched');
        return false;
      }

      if(result_message[0].data[0].type!=undefined){
        $('#mrVoipType').val(event.data); 
        $('#mrVoipType').click();
      }

      else if(result_message[0].data[0].qno!=undefined){
        $('#mrVoipQData').val(event.data); 
        $('#mrVoipQData').click();
      }
      
      else if(result_message[0].data[0].queue_name!=undefined){
        $('#ques_list').val(event.data); 
        $('#ques_list').click();
      }  
      // else if(result_message[0].data[0].agent_name!=undefined){
      //   $('#agent_list').val(event.data); 
      //   $('#agent_list').click();
      // }  
      else if(result_message[0].data[0].q_name!=undefined){
        
        if(result_message[0].data[0].q_name != "" && result_message[0].data[0].q_name != null)
    {
      
            $('#Qcalldatas').val(event.data); 
            $('#Qcalldatas').click();
            $('#recordNotFound').val('check');
            $('#recordNotFound').click();
    }
    else {
      
      $('#recordNotFound').val('');
      $('#recordNotFound').click();
      $('#Qcalldatas').val(); 
    // $('#Qcalldatas').load();
    }
    } 

    }
    this.websocket.onerror = function(event){
      console.log('error');
    }
    this.websocket.onclose = function(event){
      console.log('close');
    }  
  }
  filterItemsOfType(type){
    return this.queueData.filter(x => x.select == type);
}


slectAllQue(){
  if($("#selectAllQ").prop("checked")) {
    $(".queues_check").prop("checked", true);
} else {
    $(".queues_check").prop("checked", false);
} 
}



  addQueue(){
    $('#addQueue').modal('show');
  }
  addUsers(){
    $('#addUsers').modal('show');
  }
  


  sendOnload(){
    var socket_message  = '[{"cust_id":"'+this.has_hard_id+'","data":[{"Name":"onload","Type":"advance_wallboard_3"}]}]' ;
    this.websocket.send(socket_message);
   }
  
  
  reloadQueue(){
    var socket_message  = '[{"cust_id":"'+this.has_hard_id+'","data":[{"Name":"reset"}]}]';
    this.websocket.send(socket_message);
  }

  changeData(){
   let socketData = $('#ques_list').val(); 
  let mData = JSON.parse(socketData);
  this.queueData = mData[0].data;
  }


  getQueues(){
    var mainQueues = $('.queues_check:checked').map(function(){
      return this.value;
  }).get();


  if(mainQueues == ''){
    iziToast.warning({
      message: "Please Select Atleast One",
      position: 'topRight'
    });
    return false;
  }

   this.has_hard_id = localStorage.getItem('hardware_id');
   mainQueues = '[{"cust_id":"'+this.has_hard_id+'","data":['+mainQueues.join()+']}]';
   this.websocket.send(mainQueues);
   $('#addQueue').modal('hide');
  }



fullWindow(){
var element = document.getElementById("wallboardFullPanel");
element.classList.toggle("fullSize");
}







changeWallType(){
    let socketData = $('#mrVoipType').val(); 
    let mData = JSON.parse(socketData);
    this.socketData = mData[0].data[0];


     var type = this.socketData.type;
    var val = this.socketData.value;

    if(type == 'queues'){
      this.callques = this.socketData.value.split(',');
    } 
    else if(type == 'idleagents'){
      this.idleagents = this.socketData.value.split(',');
    }
    else if(type == 'activeagents'){
      this.activeagents = this.socketData.value.split(',');
    }
    else if(type == 'wrapupagents'){
      this.wrapupagents = this.socketData.value.split(',');
    }
    else if(type == 'notreadyagents'){
      this.notreadyagents = this.socketData.value.split(',');
    } else {
      $("#"+type).text(val);
    }
  }

  checkLicenseKey(){
    let access_token: any=localStorage.getItem('access_token');

    let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"check_hardware","user_id":"'+this.loginUser+'"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.data.value=='1'){
        this.initSocket();            
      } else {
        iziToast.error({
          message: "Your Licence Key is expired!.. please enter your key or contact admin",
          position: 'topRight'
          });
          $("#addLicence").modal({"backdrop": "static"});
          this.show_act_wall = true;
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }



  activateLicenseKey(){
    let access_token: any=localStorage.getItem('access_token');
    let l_key: any=$('#licence_key').val();
    if(l_key == ""){
      iziToast.error({
        message: "Please enter the licence key",
        position: 'topRight'
        });
        return false;
    }
    let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"check_license","user_id":"'+this.loginUser+'","license_key":"'+l_key+'"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.data.value==1){
        localStorage.setItem('hardware_id', response.result.data.hardware_id);
        this.initSocket();
          iziToast.success({
            message: "Wallboard activated",
            position: 'topRight'
            });
            $("#addLicence").modal('hide');
            
      } else {
        iziToast.error({
          message: "Please enter a valid key",
          position: 'topRight'
          });
         
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }
  showdoc(link){   
    this.doc_link=link;
   $("#document_model").modal('show');   
  }
  changeAgents(){
    let socketData = $('#agent_list').val(); 
    let mData = JSON.parse(socketData);
    this.agentsData = mData[0].data;
  }

  checkMe(){
    this.NoQueueCalls = $('#recordNotFound').val();

    if(this.NoQueueCalls != 'check'){     
           this.Qcalldatas =[];
    }

  }

  Qcalldatasfunc(){
    let socketData = $('#Qcalldatas').val(); 
    let mData = JSON.parse(socketData);
      this.Qcalldatas = mData[0].data;
  }
  tranferbutton(id,hard_id){    
    var options = {};
    $.map(this.agentsData,
        function(o) {options[o.agent_number] = o.agent_number;});
  // console.log(options);
  // console.log(this.agentsData);
  const trans =   Swal.fire({
  title: 'Select Extenstion',
  input: 'select',
  inputOptions:options,
  inputPlaceholder: 'Select Extension',
  
  confirmButtonText: 'Transfer',
  showCancelButton: true,
  }).then(function (inputValue) {
  if (inputValue.value != "" && inputValue.value != null) {
          var transfer = '[{"cust_id":"'+hard_id+'","data":[{"Name":"calltransfer","callid":"'+id+'","extension":"'+inputValue.value+'"}]}]';
          $('#transfercall').val(transfer);
          $('#transfercall').click(); 
                 }
     });
  }
  
  transfercall(){
  var transfer =$('#transfercall').val();
  this.websocket.send(transfer);
  }
  
  callbargein(id,hard_id,ext){ 
  var baregecall = '[{"cust_id":"'+hard_id+'","data":[{"Name":"bargein","Managerno":"'+ext+'","extension":"'+id+'"}]}]';
  this.websocket.send(baregecall);
  }
  
  callwhisper(id,hard_id,ext){
              var data = '[{"cust_id":"'+hard_id+'","data":[{"Name":"whisper","Managerno":"'+ext+'","extension":"'+id+'"}]}]';
              this.websocket.send(data);
  }
  
  calllisten(id,hard_id,ext){
              var data = '[{"cust_id":"'+hard_id+'","data":[{"Name":"listen","Managerno":"'+ext+'","extension":"'+id+'"}]}]';
               this.websocket.send(data);
  }
}
