import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast:any;
import Swal from 'sweetalert2'
declare var calltimer: any;
// declare var stoptimer: any;


@Component({
  selector: 'app-custom-wallboard-six',
  templateUrl: './custom-wallboard-six.component.html',
  styleUrls: ['./custom-wallboard-six.component.css']
})
export class CustomWallboardSixComponent implements OnInit {
  websocket;
  user_type;
  loginUser;
  admin_id;
  admin_permision;
  has_admin;
  has_user;
  queue_value;
  has_hard_id;
  show_act_wall;
  stoptimer;
  socketData;
  intervalvalue=0;
  answered =0;
  abandoned=0;
  agentbusy=0;
  total=0;
  waitingtime=0;
  waiting=0;
  talktime=0;


  constructor(private serverService: ServerService, private router:Router) {  }

  ngOnInit() {
  // calltimer('start');
 this.updateTime();
   this.stoptimer = setInterval(this.updateTime,1000);

    this.user_type = localStorage.getItem('user_type');
    this.loginUser = localStorage.getItem('userId');
    this.admin_id = localStorage.getItem('admin_id');
  
    this.admin_permision = localStorage.getItem('admin_permision');
  
    if(this.user_type == 'Admin' || this.admin_permision == '1'){
      this.has_admin = true;
    } else {
      this.has_admin = false;
      this.has_user = true;
    }
    this.has_hard_id = localStorage.getItem('hardware_id');
    if(this.has_hard_id == ""){
        $("#addLicence").modal({"backdrop": "static"});
        this.show_act_wall = true;
    } else {
      this.checkLicenseKey();
      // this.getagentsdata();
    }
   
  }
ngOnDestroy(): void {

  clearInterval(this.stoptimer);
}
updateTime(){
  var date = new Date();
  // alert(date.getDate())
  // alert(date.getMonth());
var month =date.getMonth()+1;
  var goTime = document.getElementById("gotime");
  goTime.innerHTML = date.toLocaleTimeString();
  var setDate = document.getElementById("getdate");
  setDate.innerHTML = date.getDate() + "-" +month+"-" + date.getFullYear();     
  
}
  initSocket(){
    this.has_hard_id = localStorage.getItem('hardware_id');
   
    if(this.admin_id == '64'){
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4002"); 
    } else if(this.admin_id == '201'){
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4003"); 
    } else {
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4001"); 
    }
    
    this.websocket.onopen = function(event) { 
      $('#sendonload').click();
      console.log('custom walboard 6 connected');
    }

    this.websocket.onmessage = function(event) {
      // console.log(event.data);
      var result_message = JSON.parse(event.data);
       console.log(result_message);
      this.has_hard_id = localStorage.getItem('hardware_id');
      if(result_message[0].cust_id == this.has_hard_id){
        console.log('matched');
      } else {
        console.log('not matched');
        return false;
      }

 
      
      if(result_message[0].data[0].type!=undefined){
        $('#mrVoipType').val(event.data); 
        $('#mrVoipType').click();
      }  
      

    }
    this.websocket.onerror = function(event){
      console.log('error');
      $('#reconnect_socket').click();

    }
    this.websocket.onclose = function(event){
      console.log('close');

      console.log('Socket Disconnected.Reconnection will be attempted');
      $('#reconnect_socket').click();
    } 
    
  }
  changeWallType(){
    let socketData = $('#mrVoipType').val(); 
  let mData = JSON.parse(socketData);
  this.socketData = mData[0].data[0];



if(this.socketData.type == "awb6_intervalvalue"){
    this.intervalvalue = this.socketData.value;
} if(this.socketData.type == "awb6_waiting"){
    this.waiting = this.socketData.value;
} 
if(this.socketData.type == "awb6_answered"){
  this.answered = this.socketData.value;
}if(this.socketData.type == "awb6_talktime"){
  this.talktime = this.socketData.value;
}
if(this.socketData.type == "awb6_abandoned"){
  this.abandoned = this.socketData.value;
}
if(this.socketData.type == "awb6_agentbusy"){
  this.agentbusy = this.socketData.value;
}
if(this.socketData.type == "awb6_total"){
  this.total = this.socketData.value;
}
if(this.socketData.type == "awb6_waitingtime"){
  this.waitingtime = this.socketData.value;
}
  }
  checkLicenseKey(){
    let access_token: any=localStorage.getItem('access_token');

    let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"check_hardware","user_id":"'+this.loginUser+'"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.data.value=='1'){
        this.initSocket();            
      } else {
        iziToast.error({
          message: "Your Licence Key is expired!.. please enter your key or contact admin",
          position: 'topRight'
          });
          $("#addLicence").modal({"backdrop": "static"});
          this.show_act_wall = true;
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }

  activateLicenseKey(){
    let access_token: any=localStorage.getItem('access_token');
    let l_key: any=$('#licence_key').val();
    if(l_key == ""){
      iziToast.error({
        message: "Please enter the licence key",
        position: 'topRight'
        });
        return false;
    }
    let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"check_license","user_id":"'+this.loginUser+'","license_key":"'+l_key+'"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.data.value==1){
        localStorage.setItem('hardware_id', response.result.data.hardware_id);
        this.initSocket();
          iziToast.success({
            message: "Wallboard activated",
            position: 'topRight'
            });
            $("#addLicence").modal('hide');
            
      } else {
        iziToast.error({
          message: "Please enter a valid key",
          position: 'topRight'
          });
         
      }
    }, 
    (error)=>{
        console.log(error);
    });
  }
    
sendOnload(){
  var socket_message  =  '[{"cust_id":"'+this.has_hard_id+'","data":[{"Name":"onload","Type":"advance_wallboard_6"}]}]' ;
  this.websocket.send(socket_message);
 }



  ScheduleReset(hard_id,val){
    // alert(val);
    // console.log(val);
    if(val == '' || val == undefined)
        val = "";
    var options = {"0":"0","5":"5","10":"10","15":"15"}; 
  // console.log(options);
  const trans =   Swal.fire({
  title: 'Update Abandoned Interval Time',
  input: 'select',
  inputOptions:options,
  inputPlaceholder: 'Select your Time',
  confirmButtonText: 'Confirm',
  showCancelButton: true,
  inputValue: val,
  }).then(function (inputValue) {
  if (inputValue.value != "" && inputValue.value != null) {
          var Schedule = '[{"cust_id":"'+hard_id+'","data":[{"Name":"abandonedinterval","option":"'+inputValue.value+'"}]}]';
          $('#Schedule').val(Schedule);
          $('#Schedule').click();       
  
                 }else{
                  // iziToast.error({
                  //   message: "You have not selected any schedule",
                  //   position: 'topRight'
                  //   });
                 }
     });
  }
  
  Schedulerst(){
    var transfer =$('#Schedule').val();
    let mData = JSON.parse(transfer);
  
    this.queue_value = mData[0].data[0].option;
    // alert(this.queue_value);
    this.websocket.send(transfer);
    iziToast.success({
      message: "Abandoned Interval Time was updated",
      position: 'topRight'
      });
    }

    fullWindow(){
      var element = document.getElementById("wallboardFullPanel");
      element.classList.toggle("fullSize");
      }
}
