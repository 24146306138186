import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facebook',
  templateUrl: './facebook.component.html',
  styleUrls: ['./facebook.component.css']
})
export class FacebookComponent implements OnInit {
applicationId;

  constructor() { }
// 149774833727652
ngOnInit() {
(window as any).fbAsyncInit = () => {
(window as any).FB.init({
appId      : '228820218341303',
cookie     : true,
xfbml      : true,
version    : 'v3.2'
});
(window as any).FB.AppEvents.logPageView();
};
( function(d, s, id) {
var js, fjs = d.getElementsByTagName(s)[0];
if (d.getElementById(id)) {return;}
js = d.createElement(s); js.id = id;
js.src = "https://connect.facebook.net/en_US/sdk.js";
fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));
}

  oauthLogin(socialProvider: string) {
    (window as any).FB.login((loginResponse) => {
      // localStorage.setItem(AppSettings.Authcookie, loginResponse.authResponse.accessToken);
      // this.showAuthUser = true;
      // this.getUserInfo(loginResponse.authResponse.userID);
      // this.isValidCredentials = true;
      console.log(loginResponse)
    },  { scope: "public_profile,email,pages_messaging,pages_messaging_phone_number" });
  }
// var pageAccessToken = 'EAACIOCvCuKQBAApKodRiXhZBG3a4DKqn9rAtAgpclRiGthXnIbjtVVlrk9dWTMjSp8iWZBrcmGJV2SzCjEZAi2P2q6M9TBKrvdMTwIQKO9YDFoWZC5o9bt9OHfx5fuK3ACBlt5lv0hqxsYZB2ccY1dmtMDMAYJpoXgcx9XhndSeBSQ92Iycr77Wg7ypcEoviJc3uQ1ZCDzZACAZCH58pxLhdvKNtftbRX0wZD';
// (window as any).FB.api('/me/conversations', {
//   access_token : pageAccessToken
// });

  // }

  getUserInfo(userId) {
    console.log("UserId" + userId);
    let self = this;
    (window as any).FB.api(
      "/" + userId + '?fields=name,accounts,email',
      (result) => {
      	console.log(result);
        if (result && !result.error) {
          // self.userName = result.name;
          // self.userEmail = result.email;
          // self.pageaccountDetails = result.accounts.data
        }
        else {
          // this.isValidCredentials = false;
        }
      });
  }

  // ngOnInit(): void {

  //   let self = this;
  //   // this.loaderService.show();
  //   this.applicationId = "149774833727652";  // **Enter your Created FB App's ID**
  //   self.loadFBSDK();
  // }  

// loadFBSDK() {
//     let self = this;
//     (<any>window).fbAsyncInit = () => {
//       FB.init({
//         appId: self.applicationId,
//         xfbml: false,
//         version: 'v2.9'
//       });
//     };

//     (function (d, s, id) {
//       let js, fjs = d.getElementsByTagName(s)[0];
//       if (d.getElementById(id)) { return; }
//       js = d.createElement(s); js.id = id;
//       js.src = "//connect.facebook.net/en_US/sdk.js";
//       fjs.parentNode.insertBefore(js, fjs);
//     }(document, 'script', 'facebook-js-sdk'));
//   }


}
