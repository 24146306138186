import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
import * as CanvasJS from '../../assets/js/canvasjs.min';
declare var $:any;
declare var iziToast:any;
declare var splineData: any;
declare var socketSpline: any;
import Swal from 'sweetalert2';


@Component({
  selector: 'app-custom-wallboard-four',
  templateUrl: './custom-wallboard-four.component.html',
  styleUrls: ['./custom-wallboard-four.component.css']
})
export class CustomWallboardFourComponent implements OnInit {

  websocket;
  socketData;
  user_type;
  loginUser;
  queueData;
  agentsData;
  answered_Call = 0;
  abandoned_call = 0;
  waiting_call = 0;
  incall = 0;
  idle = 0;
  busy = 0;
  login = 0;
  nready = 0;
  perabandoned = 0;
  peranswered = 0;
  talktime = 0;
  waitingtime = 0;
  handlingtime = 0;
  agentbusy =0;
  agentsQueData;
  avlagent = 0;
quesData;
gaugeDats;
show_act_wall = false;
has_admin =false;
has_user =false;
datasd;
quesDataGUGQName;
quesDataGQName;
chart = {
  type: 'Gauge',
  data: [
    ['Memory', 50] 
   ],
  options: {
    width: 500, height: 500,
  
    greenFrom: 0,
    greenTo: 75,
    redFrom: 90,
    redTo: 100,
    yellowFrom: 75,
    yellowTo: 90,
    minorTicks: 5,
    majorTicks : ['0','5','10','15','20','25']
  }
};

has_hard_id;
admin_id;
doc_link;




  constructor(private serverService: ServerService, private router:Router) {


   }


  ngOnInit() {
    this.user_type = localStorage.getItem('user_type');
    this.loginUser = localStorage.getItem('userId');
    this.admin_id = localStorage.getItem('admin_id');
  
    this.user_type = localStorage.getItem('user_type');
    if(this.user_type == 'Admin'){
      this.has_admin = true;
    } else {
      this.has_user = true;
    }
  
    this.has_hard_id = localStorage.getItem('hardware_id');
    if(this.has_hard_id == ""){
        $("#addLicence").modal({"backdrop": "static"});
        this.show_act_wall = true;
    } else {
      this.checkLicenseKey();
    }
  
    }


    initSocket() {
    this.user_type = localStorage.getItem('user_type');
    this.loginUser = localStorage.getItem('userId');
    // if(this.loginUser == '64'){
    //   this.websocket = new WebSocket("wss://socket.mconnectapps.com:5013/"); 
    // } else if(this.loginUser == '164'){
    //   this.websocket = new WebSocket("wss://socket.mconnectapps.com:5014/"); 
    // } else if(this.loginUser == '201'){
    //   this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4003"); 
    //   // this.websocket = new WebSocket("wss://socket.mconnectapps.com:5014/"); 
    // } else {
    //   this.websocket = new WebSocket("wss://socket.mconnectapps.com:5012/"); 
    // }

    if(this.admin_id == '64'){
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4002"); 
    } else if(this.admin_id == '201'){
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4003"); 
    } else {
      this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4001"); 
    }


    this.websocket.onopen = function(event) { 
      $('#sendonload').click();
      console.log('socket connected');
    }

    this.websocket.onmessage = function(event) {
      var result_message: any = JSON.parse(event.data);
      this.has_hard_id = localStorage.getItem('hardware_id');
      if(result_message.cust_id == this.has_hard_id || result_message[0].cust_id == this.has_hard_id){
        console.log('matched');
      } else {
        console.log('not matched');
        return false;
      }





      // if(result_message.type!=undefined){
      //   $('#mrVoipType').val(event.data); 
      //   $('#mrVoipType').click();
      
      // }
      // else if(result_message[0].qno!=undefined){
      //   $('#mrVoipQData').val(event.data); 
      //   $('#mrVoipQData').click();
      // }


      if(result_message[0].data[0].type!=undefined){
        $('#mrVoipType').val(event.data); 
        $('#mrVoipType').click();
      }

      else if(result_message[0].data[0].qno!=undefined){
        $('#mrVoipQData').val(event.data); 
        $('#mrVoipQData').click();
      }
      if(result_message[0].data[0].gqueue_name!=undefined){
        $('#mrVoipGQName').val(event.data); 
        $('#mrVoipGQName').click();
      }

      else if(result_message[0].data[0].gugqueue_name!=undefined){
        $('#mrVoipGUGQName').val(event.data); 
        $('#mrVoipGUGQName').click();
      } 
      else if(result_message[0].data[0].queue_name!=undefined){
        $('#ques_list').val(event.data); 
        $('#ques_list').click();
      } 
      
      else if(result_message[0].gtype!=undefined){
        $('#gaugeValue').val(event.data);
        $('#gaugeValue').click();
      }
      else if(result_message[0].stype!=undefined){
        $('#splineValue').val(event.data);
        $('#splineValue').click();
      } 
       else {
        
      }  
      
    }
    this.websocket.onerror = function(event){
      console.log('error');
    }
    this.websocket.onclose = function(event){
      console.log('close');
    } 

 

    splineData();
    
    
  }




  addQueue(){
    $('#addQueue').modal('show');
  }
  addGQName(){
    $('#addGQName').modal('show');
  }
  addGUGQName(){
    $('#addGUGQName').modal('show');
  }
  addUsers(){
    $('#addUsers').modal('show');
  }
  
  sendOnload(){
    // var socket_message  =  '[{"Name":"onload"}]';
    var socket_message  = '[{"cust_id":"'+this.has_hard_id+'","data":[{"Name":"onload","Type":"advance_wallboard_4"}]}]' ;
    this.websocket.send(socket_message);
  }
  reloadQueue(){
    var socket_message  = '[{"cust_id":"'+this.has_hard_id+'","data":[{"Name":"reset"}]}]';
    this.websocket.send(socket_message);
  }
  changeData(){
    let socketData = $('#ques_list').val(); 
    let mData = JSON.parse(socketData);
    this.queueData = mData[0].data;
  }
  
  getQueues(){
    var mainQueues = $('.queues_check:checked').map(function(){
      return this.value;
  }).get();

  if(mainQueues == ''){
    iziToast.warning({
      message: "Please Select Atleast One",
      position: 'topRight'
    });
    return false;
  }

  //  mainQueues = '['+mainQueues.join()+']';
  //  this.websocket.send(mainQueues);
  //  $('#addQueue').modal('hide');

   this.has_hard_id = localStorage.getItem('hardware_id');
   mainQueues = '[{"cust_id":"'+this.has_hard_id+'","data":['+mainQueues.join()+']}]';
   this.websocket.send(mainQueues);
   $('#addQueue').modal('hide');


  }

  getGUGQName(){
    var mainQueues = $('.GUGQName_check:checked').map(function(){
      return this.value;
  }).get();

  if(mainQueues == ''){
    iziToast.warning({
      message: "Please Select Atleast One",
      position: 'topRight'
    });
    return false;
  }

  //  mainQueues = '['+mainQueues.join()+']';
  //  this.websocket.send(mainQueues);
  //  $('#addGUGQName').modal('hide'); 


   this.has_hard_id = localStorage.getItem('hardware_id');
   mainQueues = '[{"cust_id":"'+this.has_hard_id+'","data":['+mainQueues.join()+']}]';
   this.websocket.send(mainQueues);
   $('#addGUGQName').modal('hide');




  }


  getQueuesGQName(){
    var mainQueues = $('.GQName_check:checked').map(function(){
      return this.value;
  }).get();

  if(mainQueues == ''){
    iziToast.warning({
      message: "Please Select Atleast One",
      position: 'topRight'
    });
    return false;
  }

  //  mainQueues = '['+mainQueues.join()+']';
  //  this.websocket.send(mainQueues);
  //  $('#addGQName').modal('hide'); 


   this.has_hard_id = localStorage.getItem('hardware_id');
   mainQueues = '[{"cust_id":"'+this.has_hard_id+'","data":['+mainQueues.join()+']}]';
   this.websocket.send(mainQueues);
   $('#addGQName').modal('hide');


  }


  changeWallType(){
    let socketData = $('#mrVoipType').val(); 
    let mData = JSON.parse(socketData);
    this.socketData = mData[0].data[0];
    $('#'+this.socketData.type).text(this.socketData.value)
  }

  mrVoipGQName(){
    let socketData = $('#mrVoipGQName').val(); 
    let mData = JSON.parse(socketData);
    this.quesDataGQName = mData[0].data;


  }

  mrVoipGUGQName(){
    let socketData = $('#mrVoipGUGQName').val(); 
    let mData = JSON.parse(socketData);
    this.quesDataGUGQName = mData[0].data;
  }
 
  changeQuesData(){
    let socketData = $('#mrVoipQData').val(); 
    this.quesData = JSON.parse(socketData);
  }


  lodatada(){
    let socketData = $('#gaugeValue').val(); 
    let datas = JSON.parse(socketData);
    this.datasd = datas[0].value;
    
  }

  splinchart(){
    let socketData = $('#splineValue').val(); 
    let datas = JSON.parse(socketData);
    let mdata = datas[0].value;
    socketSpline(mdata);
  }


  fullWindowTable(){
  var element = document.getElementById("wallboardFullPanel-1");
  element.classList.toggle("fullSize");
}

fullWindowLineChart(){
var element = document.getElementById("wallboardFullPanel-2");
element.classList.toggle("fullSize");
}

fullWindowChart(){
var element = document.getElementById("wallboardFullPanel-3");
element.classList.toggle("fullSize");
}












checkLicenseKey(){
  let access_token: any=localStorage.getItem('access_token');

  let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"check_hardware","user_id":"'+this.loginUser+'"}}';
  this.serverService.sendServer(api_req).subscribe((response:any) => {
    if(response.result.data.value=='1'){
      this.initSocket();            
    } else {
      iziToast.error({
        message: "Your Licence Key is expired!.. please enter your key or contact admin",
        position: 'topRight'
        });
        $("#addLicence").modal({"backdrop": "static"});
        this.show_act_wall = true;
    }
  }, 
  (error)=>{
      console.log(error);
  });
}



activateLicenseKey(){
  let access_token: any=localStorage.getItem('access_token');
  let l_key: any=$('#licence_key').val();
  if(l_key == ""){
    iziToast.error({
      message: "Please enter the licence key",
      position: 'topRight'
      });
      return false;
  }
  let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"check_license","user_id":"'+this.loginUser+'","license_key":"'+l_key+'"}}';
  this.serverService.sendServer(api_req).subscribe((response:any) => {
    if(response.result.data.value==1){
      localStorage.setItem('hardware_id', response.result.data.hardware_id);
      this.initSocket();
        iziToast.success({
          message: "Wallboard activated",
          position: 'topRight'
          });
          $("#addLicence").modal('hide');
          
    } else {
      iziToast.error({
        message: "Please enter a valid key",
        position: 'topRight'
        });
       
    }
  }, 
  (error)=>{
      console.log(error);
  });
}
showdoc(link){   
  this.doc_link=link;
 $("#document_model").modal('show');   
}
}







